import React, { useState, useRef, useEffect } from 'react';
   import './App.css';

   function App() {
     const [isRevealed, setIsRevealed] = useState(false);
     const [isInteracting, setIsInteracting] = useState(false);
     const [canvasSize, setCanvasSize] = useState({ width: 600, height: 400 });
     const [scratchPercentage, setScratchPercentage] = useState(0);
     const [isWinner, setIsWinner] = useState(null);
     const canvasRef = useRef(null);
     const containerRef = useRef(null);

     useEffect(() => {
       const updateCanvasSize = () => {
         if (containerRef.current) {
           const { width, height } = containerRef.current.getBoundingClientRect();
           setCanvasSize({ width, height });
         }
       };

       updateCanvasSize();
       window.addEventListener('resize', updateCanvasSize);
       return () => window.removeEventListener('resize', updateCanvasSize);
     }, []);

     useEffect(() => {
       const canvas = canvasRef.current;
       const ctx = canvas.getContext('2d');
       const img = new Image();
       img.onload = () => {
         ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
         ctx.font = 'bold 24px Arial';
         ctx.fillStyle = 'black';
         ctx.textAlign = 'center';
       };
       img.src = '/ad-placeholder.jpg';
     }, [canvasSize]);

     const handleScratch = (e) => {
       if (!isInteracting) return;

       const canvas = canvasRef.current;
       const ctx = canvas.getContext('2d');
       const rect = canvas.getBoundingClientRect();
       
       let x, y;
       if (e.type.startsWith('touch')) {
         const touch = e.touches[0];
         x = touch.clientX - rect.left;
         y = touch.clientY - rect.top;
       } else {
         x = e.clientX - rect.left;
         y = e.clientY - rect.top;
       }

       ctx.globalCompositeOperation = 'destination-out';
       ctx.beginPath();
       ctx.arc(x, y, 20, 0, 2 * Math.PI);
       ctx.fill();

       const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
       const pixelData = imageData.data;
       let transparentPixels = 0;
       for (let i = 0; i < pixelData.length; i += 4) {
         if (pixelData[i + 3] === 0) transparentPixels++;
       }
       const scratchedPercentage = transparentPixels / (pixelData.length / 4);
       setScratchPercentage(scratchedPercentage * 100);
       
       if (scratchedPercentage > 0.8 && isWinner === null) {
         setIsRevealed(true);
         setIsWinner(Math.random() < 0.5);
       }
     };

     const handleStart = (e) => {
       e.preventDefault();
       setIsInteracting(true);
       handleScratch(e);
     };

     const handleEnd = () => setIsInteracting(false);

     return (
       <div ref={containerRef} className="scratch-container">
         {!isRevealed ? (
           <React.Fragment>
             <canvas
               ref={canvasRef}
               width={canvasSize.width}
               height={canvasSize.height}
               className="scratch-canvas"
               onMouseDown={handleStart}
               onMouseMove={handleScratch}
               onMouseUp={handleEnd}
               onMouseLeave={handleEnd}
               onTouchStart={handleStart}
               onTouchMove={handleScratch}
               onTouchEnd={handleEnd}
             />
             {scratchPercentage < 20 && (
               <div className="scratch-instruction">
                 Scratch to reveal your reward!
               </div>
             )}
           </React.Fragment>
         ) : (
           <div className={`result-message ${isWinner ? 'success-message' : 'failure-message'}`}>
             {isWinner ? (
               <>
                 <h2 className="animate-bounce">Congratulations!</h2>
                 <p>You've won:</p>
                 <div className="reward-text animate-pulse">
                   1 FREE usage of doculoom.com
                 </div>
                 <div className="fireworks">
                   <div className="before"></div>
                   <div className="after"></div>
                 </div>
                 <a
                   href="https://doculoom.com/?chickendinner"
                   target="_blank"
                   rel="noopener noreferrer"
                   className="claim-button animate-pulse"
                 >
                   Click here to claim your reward
                 </a>
               </>
             ) : (
               <>
                 <h2>Better luck next time!</h2>
                 <img src="/giphy.gif" alt="Rick Roll" className="rick-roll" />
                 <p>Don't give up, try again!</p>
               </>
             )}
           </div>
         )}
       </div>
     );
   }

   export default App;
